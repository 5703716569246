import { Box, Link } from "@mui/material";
import React from "react";
import whatsapplogo1 from "../asset/newwhatsapplogo.png";
import playstoreImg from "../asset/newplaystorelogo.png";
import applestoreImg from "../asset/newappstorelogo.png";
import webImg from "../asset/webImg.JPG";
import { makeStyles } from "@mui/styles";
import "./Main.css";

const useStyles = makeStyles((theme) => ({
  playstoreImg: {
    borderRadius: "7px",
    "@media (max-width: 600px)": {
      width: "8rem",
      height: "3rem",
    },
    "@media (min-width: 375px) and (max-width: 435px)": {
      width: "8rem",
      height: "3rem",
    },
    "@media (min-width: 340px) and (max-width: 374px)": {
      width: "8rem",
      height: "3rem",
    },
    "@media (min-width: 300px) and (max-width: 339px)": {
      width: "7rem",
      height: "3rem",
    },
  },

  allBox: {
    display: "flex",
    gap: "3rem",
    marginTop: "30px ",
    marginBottom: "2rem",
    marginLeft: "10%",

    "@media (max-width: 600px)": {
      //   gap: "0.5rem",
      justifyContent: "space-around",

      marginTop: "2rem",
      marginBottom: "2rem",
      marginLeft: "2%",
      marginRight: "2%",
      //   display: "none",
    },
    "@media (min-width: 300px) and (max-width: 339px)": {
      gap: "0.5rem",
      marginTop: "2rem",
      marginBottom: "2rem",
      marginLeft: "2%",
      //   display: "none",
    },
  },
}));

const FooterSecTwo = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box
        className={classes.allBox}
        sx={{
          display: { xs: "none", md: "none", lg: "flex", sm: "none" },
        }}
      >
        <Box className={classes.playstoreImgBox}>
          <Link
            href={"https://jopmw.test-app.link/Playstore"}
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <img src={playstoreImg} className={classes.playstoreImg} alt="" />
          </Link>
        </Box>

        <Box className={classes.applestoreImgBoxBox}>
          <img src={applestoreImg} className={classes.playstoreImg} alt="" />
        </Box>

        <Box
          className={classes.webImg}
          sx={{ display: { xs: "none", md: "none", sm: "none" } }}
        >
          <img src={webImg} className={classes.playstoreImg} alt="" />
        </Box>
      </Box>

      {/* <Box
        sx={{
          marginLeft: { xs: "2%", md: "10rem", lg: "20rem", sm: "2%" },
          marginTop: { xs: "10rem", md: "10rem", lg: "10rem", sm: "2rem" },
          display: { xs: "none", md: "flex", lg: "none", sm: "flex" },
          gap: { xs: "2rem", md: "2rem", lg: "2rem", sm: "2rem" },
          marginBottom: { xs: "4rem", md: "4rem", lg: "8rem", sm: "4rem" },
        }}
      >
        <Box className={classes.playstoreImg}>
          <Link
            href={
              "https://jopmw.test-app.link/Playstore"
            }
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <img src={playstoreImg} className={"webImg1"} alt="" />
          </Link>
        </Box>

        <Box className={classes.applestoreImg}>
          <img src={applestoreImg} className={"webImg1"} alt="" />
        </Box>

        <Box className={classes.webImg}>
          <img src={webImg} className={"webImg1"} alt="" />
        </Box>
      </Box> */}
      <div>
        <div className="alllinkDiv1">
          {/* <div className="linkDiv"></div> */}
          <div className="linkDivinner">
            <Link
              href={"https://jopmw.test-app.link/Playstore"}
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              <img
                style={{ borderRadius: "10px" }}
                src={playstoreImg}
                alt=""
                className="storeimage"
              />
            </Link>

            <Link
              href={"https://jopmw.test-app.link/AppStore"}
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              <img
                style={{ borderRadius: "10px" }}
                src={applestoreImg}
                alt=""
                className="storeimage"
              />
            </Link>
          </div>
          <Link
            href={"https://wa.link/ks4v9u"}
            target="_blank"
            without
            rel="noopener noreferrer"
            style={{
              textDecorationLine: "none",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            <div className="whatsapplogodiv">
              <img className="whatsapplogolink" src={whatsapplogo1} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FooterSecTwo;
