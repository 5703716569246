import { Box, Paper, Typography, Avatar } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import customerImg from "../asset/customerImg.jpg";
import ebiComfortImg from "../asset/ebiComfortImg.jpeg";
import zahkiImg from "../asset/zahkiImg.jpeg";
import ridwanImg from "../asset/ridwanImg.jpeg";
import trendImg from "../asset/trendImg.jpeg";
import tosinImg from "../asset/tosinImg.jpeg";
import Carousel from "./Carousel";
import UserReviewCarousel from "./UserReviewCarousel";

const useStyles = makeStyles((theme) => ({
  customersTheader: {
    fontSize: "5rem !important",
    // fontFamily: "Helvetica",
    fontWeight: "bold !important",
    color: "#22AACC",
    // marginLeft: "20rem !important",
  },
  allBox: {
    // color: "#22AACC !important",
    backgroundColor: "rgba(34, 170, 204, 0.05)",
    // marginLeft: "-40rem",
  },
  secondBox: {
    marginLeft: "20rem",
  },
  hereT: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    color: "black !important",
    textAlign: "center",
    marginBottom: "3rem !important",
  },
  flexBoxB: {
    display: "flex",
    gap: "7rem",
  },
  flexContentBox: {
    height: "60rem",
    backgroundColor: "white",
    width: "45rem",
    borderRadius: "2rem",
  },
  carouselMobilePaper: {
    // width: { xs: '100%', md:'100%', lg:'45rem', sm: '90%' },
    // borderRadius: { xs: '10px', md:'10px', lg:'1rem', sm: '1rem' },
    // height: { xs: '50rem', md:'50rem', lg:'60rem', sm: '50rem' },
    // marginBottom:{xs:'1rem', md: 'auto', lg:'5rem', sm:'2rem'},
    // marginRight: {xs: 'none', md:'none', lg:'3rem', sm: 'none'},
    "@media only screen and (max-width: 600px)": {
      width: "90%",
      borderRadius: "2rem",
      height: "50rem",
      marginBottom: "1rem",
      marginLeft: "2%",
      marginRight: "2%",
    },
  },
  carouselDesktopPaper: {
    //     width: { xs: '100%', md:'100%', lg:'45rem', sm: '90%' },
    //     borderRadius: { xs: '10px', md:'10px', lg:'1rem', sm: '1rem' },
    //     height: { xs: '50rem', md:'50rem', lg:'60rem', sm: '50rem' },
    //     marginBottom:{xs:'1rem', md: 'auto', lg:'5rem', sm:'2rem'},
    //     marginRight: {xs: 'none', md:'none', lg:'3rem', sm: 'none'},
    width: "100%",
    borderRadius: "2rem",
    height: "45rem",
    marginBottom: "1rem",
    // marginLeft:'5%',
    // marginRight:'5%',
  },
}));

const CustomersDelights = () => {
  const classes = useStyles();

  const users = [
    {
      id: 1,
      name: "John Doe",
      image: "https://example.com/john-doe.jpg",
      review: "This carousel is amazing!",
    },
    {
      id: 2,
      name: "Jane Doe",
      image: "https://example.com/jane-doe.jpg",
      review: "I love using this carousel for my website!",
    },
    {
      id: 3,
      name: "Peter Parker",
      image: "https://example.com/peter-parker.jpg",
      review: "This carousel is very user-friendly and easy to set up!",
    },
  ];

  return (
    <React.Fragment>
      <Typography
        sx={{
          marginLeft: { xs: "2rem", md: "auto", lg: "10rem", sm: "2rem" },
          fontWeight: { xs: "bold", md: "bold", lg: "bold", sm: "bold" },
          fontSize: { xs: "3rem", md: "3rem", lg: "2rem", sm: "2rem" },
          paddingTop: { xs: "13rem", md: "auto", lg: "10rem", sm: "13rem" },
          // marginTop: { xs: "30rem", md: "5rem", lg: "5rem", sm: "30rem" },
          //   width: { xs: "40%", md: "auto", lg: "auto", sm: "2rem" },
          lineHeight: { xs: "1", md: "2.0", lg: "auto", sm: "1" },
          color: "#22AACC",
        }}
      >
        Customer's Delight
      </Typography>
      <Box
        className={classes.allBox}
        sx={{
          //   marginLeft: { xs: "-5%", md: "0" },
          //   marginRight: { xs: "-5%", md: "0" },
          height: { xs: "50rem", md: "50rem", lg: "70rem", sm: "50rem" },
        }}
      >
        <Box
          className={classes.secondBox}
          sx={{
            marginLeft: { xs: "5%", md: "auto", lg: "5rem", sm: "2rem" },
            marginTop: { xs: "2rem", md: "auto", lg: "auto", sm: "auto" },
            marginRight: { xs: "5%", md: "auto", lg: "5rem", sm: "2rem" },
          }}
        >
          <Box>
            <Typography
              className={classes.hereT}
              sx={{
                paddingTop: { xs: "4rem", sm: "4rem", lg: "12rem" },
              }}
            >
              Here is what our customers are saying about our service
            </Typography>
          </Box>
          <Box
            className={classes.secondBox}
            sx={{
              marginLeft: { xs: "5%", md: "auto", lg: "15rem", sm: "2rem" },
              marginTop: { xs: "2rem", md: "auto", lg: "auto", sm: "auto" },
              marginRight: { xs: "5%", md: "auto", lg: "5rem", sm: "2rem" },
            }}
          >
            <Box
              className={classes.flexBoxB}
              sx={{
                display: { xs: "none", md: "none", lg: "flex", sm: "none" },
              }}
            >
              <Carousel show={3} infiniteLoop>
                <Paper className={classes.carouselDesktopPaper} elevation={3}>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "flex",
                        sm: "flex",
                      },
                      flexDirection: { lg: "row" },
                    }}
                  >
                    <Box
                      //   className={classes.profileBox}
                      sx={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        borderRadius: "50%",
                        // transform: "rotate(-30deg)",
                      }}
                    >
                      <Avatar
                        alt="Ebi Comfort"
                        src={ebiComfortImg}
                        sx={{
                          height: {
                            xs: "5rem",
                            md: "5rem",
                            lg: "10rem",
                            sm: "5rem",
                          },
                          width: {
                            xs: "5rem",
                            md: "5rem",
                            lg: "10rem",
                            sm: "5rem",
                          },
                          transform: "rotate(30deg)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: {
                          //   xs: "4rem",
                          md: "auto",
                          lg: "8rem",
                          //   sm: "2rem",
                        },
                        marginLeft: {
                          xs: "2rem",
                          md: "auto",
                          lg: "0",
                          sm: "1rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            md: "16px",
                            lg: "18px",
                            sm: "16px",
                          },
                          fontWeight: {
                            xs: "bold",
                            md: "bold",
                            lg: "bold",
                            sm: "bold",
                          },
                        }}
                      >
                        Ebi Comfort
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#22AACC",
                        }}
                      >
                        Lagos, Magodo
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: {
                        xs: "1rem",
                        md: "auto",
                        lg: "2rem",
                        sm: "2rem",
                      },
                      marginRight: "2rem",
                      marginTop: {
                        xs: "2rem",
                        md: "auto",
                        lg: "3rem",
                        sm: "2rem",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Coinstick, I'm giving you 4 stars bcos of love I felt with
                      your services. You are reliable, fast, consistent and give
                      a good rate in all transactions we do. I appreciate so
                      much.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      New development should set in like your mobileapp.
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        // marginBottom:'3rem',
                      }}
                    >
                      Don't relent on the good work you have started.
                    </Typography>
                  </Box>
                </Paper>

                <Paper className={classes.carouselDesktopPaper} elevation={3}>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "flex",
                        sm: "flex",
                      },
                    }}
                  >
                    <Box
                      className={classes.profileBox}
                      sx={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        borderRadius: "50%",
                        transform: "rotate(-30deg)",
                      }}
                    >
                      <Avatar
                        alt="Zahki Lion"
                        src={zahkiImg}
                        sx={{
                          height: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          width: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          transform: "rotate(30deg)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: {
                          xs: "4rem",
                          md: "auto",
                          lg: "8rem",
                          sm: "2rem",
                        },
                        marginLeft: {
                          xs: "2rem",
                          md: "auto",
                          lg: "0",
                          sm: "1rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            md: "16px",
                            lg: "18px",
                            sm: "16px",
                          },
                          fontWeight: {
                            xs: "bold",
                            md: "bold",
                            lg: "bold",
                            sm: "bold",
                          },
                        }}
                      >
                        Zahki Lion
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#22AACC",
                        }}
                      >
                        Lagos, Yaba
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: {
                        xs: "1rem",
                        md: "auto",
                        lg: "2rem",
                        sm: "2rem",
                      },
                      marginRight: "2rem",
                      marginTop: {
                        xs: "2rem",
                        md: "auto",
                        lg: "3rem",
                        sm: "2rem",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Am happy with the services offer by @coinstickHQ. I
                      actually needed a genuine plug to trade my Bitcoin luckily
                      I came across them on instagram during their sponsored
                      post.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Sincerely I was scared 😦 at first because of series of
                      encounters I had in the past but decided to give them a
                      try and today am happy I made the right decision.
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        // marginBottom:'3rem',
                      }}
                    >
                      👍👍 @coinstick continue the good work
                    </Typography>
                  </Box>
                </Paper>

                <Paper className={classes.carouselDesktopPaper} elevation={3}>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "flex",
                        sm: "flex",
                      },
                    }}
                  >
                    <Box
                      className={classes.profileBox}
                      sx={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        borderRadius: "50%",
                        transform: "rotate(-30deg)",
                      }}
                    >
                      <Avatar
                        alt="Tosin Adeyemo"
                        src={tosinImg}
                        sx={{
                          height: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          width: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          transform: "rotate(30deg)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: {
                          xs: "4rem",
                          md: "auto",
                          lg: "8rem",
                          sm: "2rem",
                        },
                        marginLeft: {
                          xs: "2rem",
                          md: "auto",
                          lg: "0",
                          sm: "1rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            md: "16px",
                            lg: "18px",
                            sm: "16px",
                          },
                          fontWeight: {
                            xs: "bold",
                            md: "bold",
                            lg: "bold",
                            sm: "bold",
                          },
                        }}
                      >
                        Tosin Adeyemo
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#22AACC",
                        }}
                      >
                        Lagos, Magodo
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: {
                        xs: "1rem",
                        md: "auto",
                        lg: "2rem",
                        sm: "2rem",
                      },
                      marginRight: "2rem",
                      marginTop: {
                        xs: "2rem",
                        md: "auto",
                        lg: "3rem",
                        sm: "2rem",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      I'll rather trade with coinstick.. your service is top
                      notch. Fast transaction and very reliable!
                      <br />
                      With coinstick I have nothing to worry about.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Sincerely I was scared 😦 at first because of series of
                      encounters I had in the past but decided to give them a
                      try and today am happy I made the right decision.
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        // marginBottom:'3rem',
                      }}
                    >
                      Keep it up!
                    </Typography>
                  </Box>
                </Paper>

                <Paper className={classes.carouselDesktopPaper} elevation={3}>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "flex",
                        sm: "flex",
                      },
                    }}
                  >
                    <Box
                      className={classes.profileBox}
                      sx={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        borderRadius: "50%",
                        transform: "rotate(-30deg)",
                      }}
                    >
                      <Avatar
                        alt="Shamusideen Anuoluwa"
                        src={customerImg}
                        sx={{
                          height: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          width: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          transform: "rotate(30deg)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: {
                          xs: "4rem",
                          md: "auto",
                          lg: "8rem",
                          sm: "2rem",
                        },
                        marginLeft: {
                          xs: "2rem",
                          md: "auto",
                          lg: "0",
                          sm: "1rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            md: "16px",
                            lg: "18px",
                            sm: "16px",
                          },
                          fontWeight: {
                            xs: "bold",
                            md: "bold",
                            lg: "bold",
                            sm: "bold",
                          },
                        }}
                      >
                        Shamusideen Anuoluwa
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#22AACC",
                        }}
                      >
                        Ibadan
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: {
                        xs: "1rem",
                        md: "auto",
                        lg: "2rem",
                        sm: "2rem",
                      },
                      marginRight: "2rem",
                      marginTop: {
                        xs: "2rem",
                        md: "auto",
                        lg: "3rem",
                        sm: "2rem",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Coinstick, I'm giving you 4 stars bcos of love I felt with
                      your services. You are reliable, fast, consistent and give
                      a good rate in all transactions we do. I appreciate so
                      much.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      New development should set in like your mobileapp.
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        // marginBottom:'3rem',
                      }}
                    >
                      Don't relent on the good work you have started.
                    </Typography>
                  </Box>
                </Paper>

                <Paper className={classes.carouselDesktopPaper} elevation={3}>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "flex",
                        sm: "flex",
                      },
                    }}
                  >
                    <Box
                      className={classes.profileBox}
                      sx={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        borderRadius: "50%",
                        transform: "rotate(-30deg)",
                      }}
                    >
                      <Avatar
                        alt="Ganiyu Ridwan"
                        src={ridwanImg}
                        sx={{
                          height: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          width: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          transform: "rotate(30deg)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: {
                          xs: "0",
                          md: "auto",
                          lg: "8rem",
                          sm: "2rem",
                        },
                        marginLeft: {
                          xs: "2rem",
                          md: "auto",
                          lg: "0",
                          sm: "1rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            md: "16px",
                            lg: "18px",
                            sm: "16px",
                          },
                          fontWeight: {
                            xs: "bold",
                            md: "bold",
                            lg: "bold",
                            sm: "bold",
                          },
                        }}
                      >
                        Ganiyu Ridwan
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#22AACC",
                        }}
                      >
                        Ibadan
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: {
                        xs: "1rem",
                        md: "auto",
                        lg: "2rem",
                        sm: "2rem",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      “Phenomenal; that’s just the word for Coinstick, best at
                      what they do with maximum dedication to satisfying the
                      partners and customers needs”
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      New development should set in like your mobileapp.
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        // marginBottom:'3rem',
                      }}
                    >
                      Don't relent on the good work you have started.
                    </Typography>
                  </Box>
                </Paper>

                <Paper className={classes.carouselDesktopPaper} elevation={3}>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "flex",
                        sm: "flex",
                      },
                    }}
                  >
                    <Box
                      className={classes.profileBox}
                      sx={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        borderRadius: "50%",
                        transform: "rotate(-30deg)",
                      }}
                    >
                      <Avatar
                        alt="Akanni Trend"
                        src={trendImg}
                        sx={{
                          height: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          width: {
                            xs: "10rem",
                            md: "10rem",
                            lg: "10rem",
                            sm: "10rem",
                          },
                          transform: "rotate(30deg)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: {
                          xs: "4rem",
                          md: "auto",
                          lg: "8rem",
                          sm: "2rem",
                        },
                        marginLeft: {
                          xs: "2rem",
                          md: "auto",
                          lg: "0",
                          sm: "1rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            md: "16px",
                            lg: "18px",
                            sm: "16px",
                          },
                          fontWeight: {
                            xs: "bold",
                            md: "bold",
                            lg: "bold",
                            sm: "bold",
                          },
                        }}
                      >
                        Akanni Trend
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#22AACC",
                        }}
                      >
                        Lagos, Surulere
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: {
                        xs: "1rem",
                        md: "auto",
                        lg: "2rem",
                        sm: "2rem",
                      },
                      marginRight: "2rem",
                      marginTop: {
                        xs: "2rem",
                        md: "auto",
                        lg: "3rem",
                        sm: "2rem",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Whenever you hear @Coinstick the first thing that should
                      come to your mind is they gat the baddest rate Menhh their
                      rates are madddd!!! and they are different from any other
                      crypto plug I had traded with.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        marginBottom: {
                          xs: "2rem",
                          md: "2rem",
                          lg: "3rem",
                          sm: "2rem",
                        },
                      }}
                    >
                      Guys I can’t wait for the launch of your App trust me you
                      are going higher and please🙏🙏🙏 continue the good work
                      and improve more on the pace of transactions.
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "13px",
                          lg: "18px",
                          sm: "13px",
                        },
                        // marginBottom:'3rem',
                      }}
                    >
                      Thank You
                    </Typography>
                  </Box>
                </Paper>
              </Carousel>
            </Box>
          </Box>
        </Box>

        {/*Carousel responsive view     */}

        <Box
          className={classes.flexBoxB}
          sx={{
            display: { xs: "none", md: "none", lg: "none", sm: "none" },
            gap: "20px",
          }}
        >
          <Carousel show={1} infiniteLoop>
            <Paper className={classes.carouselMobilePaper} elevation={3}>
              <Box
                sx={{
                  display: { xs: "flex", md: "none", lg: "flex", sm: "flex" },
                }}
              >
                <Box
                  className={classes.profileBox}
                  sx={{
                    borderTop: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderBottom: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderRight: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    borderLeft: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    marginTop: "2rem",
                    marginLeft: "2%",
                    borderRadius: "50%",
                    transform: "rotate(-30deg)",
                  }}
                >
                  <Avatar
                    alt="Zahki Lion"
                    src={zahkiImg}
                    sx={{
                      height: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      width: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      transform: "rotate(30deg)",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: {
                      xs: "4rem",
                      md: "auto",
                      lg: "15rem",
                      sm: "2rem",
                    },
                    marginLeft: { xs: "2rem", md: "auto", lg: "0", sm: "1rem" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "16px",
                        lg: "18px",
                        sm: "16px",
                      },
                      fontWeight: {
                        xs: "bold",
                        md: "bold",
                        lg: "bold",
                        sm: "bold",
                      },
                    }}
                  >
                    Zahki Lion
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#22AACC",
                    }}
                  >
                    Lagos, Yaba
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: {
                    xs: "1rem",
                    md: "auto",
                    lg: "2rem",
                    sm: "2rem",
                  },
                  marginRight: "2rem",
                  marginTop: { xs: "2rem", md: "auto", lg: "3rem", sm: "2rem" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  Am happy with the services offer by @coinstickHQ. I actually
                  needed a genuine plug to trade my Bitcoin luckily I came
                  across them on instagram during their sponsored post.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  Sincerely I was scared 😦 at first because of series of
                  encounters I had in the past but decided to give them a try
                  and today am happy I made the right decision.
                </Typography>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    // marginBottom:'3rem',
                  }}
                >
                  👍👍 @coinstick continue the good work
                </Typography>
              </Box>
            </Paper>

            <Paper className={classes.carouselMobilePaper} elevation={3}>
              <Box
                sx={{
                  display: { xs: "flex", md: "none", lg: "flex", sm: "flex" },
                }}
              >
                <Box
                  className={classes.profileBox}
                  sx={{
                    borderTop: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderBottom: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderRight: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    borderLeft: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    marginTop: "2rem",
                    marginLeft: "2rem",
                    borderRadius: "50%",
                    transform: "rotate(-30deg)",
                  }}
                >
                  <Avatar
                    alt="Shamusideen Anuoluwa"
                    src={customerImg}
                    sx={{
                      height: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      width: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      transform: "rotate(30deg)",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: {
                      xs: "4rem",
                      md: "auto",
                      lg: "15rem",
                      sm: "2rem",
                    },
                    marginLeft: { xs: "2rem", md: "auto", lg: "0", sm: "1rem" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "16px",
                        lg: "18px",
                        sm: "16px",
                      },
                      fontWeight: {
                        xs: "bold",
                        md: "bold",
                        lg: "bold",
                        sm: "bold",
                      },
                    }}
                  >
                    Shamusideen Sanni
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#22AACC",
                    }}
                  >
                    Dubai
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: {
                    xs: "1rem",
                    md: "auto",
                    lg: "2rem",
                    sm: "2rem",
                  },
                  marginRight: "2rem",
                  marginTop: { xs: "2rem", md: "auto", lg: "3rem", sm: "2rem" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  Coinstick, I'm giving you 4 stars bcos of love I felt with
                  your services. You are reliable, fast, consistent and give a
                  good rate in all transactions we do. I appreciate so much.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  New development should set in like your mobileapp.
                </Typography>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    // marginBottom:'3rem',
                  }}
                >
                  Don't relent on the good work you have started.
                </Typography>
              </Box>
            </Paper>

            <Paper className={classes.carouselMobilePaper} elevation={3}>
              <Box
                sx={{
                  display: { xs: "flex", md: "none", lg: "flex", sm: "flex" },
                }}
              >
                <Box
                  className={classes.profileBox}
                  sx={{
                    borderTop: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderBottom: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderRight: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    borderLeft: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    marginTop: "2rem",
                    marginLeft: "2rem",
                    borderRadius: "50%",
                    transform: "rotate(-30deg)",
                  }}
                >
                  <Avatar
                    alt="Ganiyu Ridwan"
                    src={ridwanImg}
                    sx={{
                      height: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      width: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      transform: "rotate(30deg)",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: {
                      xs: "4rem",
                      md: "auto",
                      lg: "15rem",
                      sm: "2rem",
                    },
                    marginLeft: { xs: "2rem", md: "auto", lg: "0", sm: "1rem" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "16px",
                        lg: "18px",
                        sm: "16px",
                      },
                      fontWeight: {
                        xs: "bold",
                        md: "bold",
                        lg: "bold",
                        sm: "bold",
                      },
                    }}
                  >
                    Ganiyu Ridwan
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#22AACC",
                    }}
                  >
                    Ibadan
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: {
                    xs: "1rem",
                    md: "auto",
                    lg: "2rem",
                    sm: "2rem",
                  },
                  marginRight: "2rem",
                  marginTop: { xs: "2rem", md: "auto", lg: "3rem", sm: "2rem" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  “Phenomenal; that’s just the word for Coinstick, best at what
                  they do with maximum dedication to satisfying the partners and
                  customers needs”
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  New development should set in like your mobileapp.
                </Typography>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    // marginBottom:'3rem',
                  }}
                >
                  Don't relent on the good work you have started.
                </Typography>
              </Box>
            </Paper>

            <Paper className={classes.carouselMobilePaper} elevation={3}>
              <Box
                sx={{
                  display: { xs: "flex", md: "none", lg: "flex", sm: "flex" },
                }}
              >
                <Box
                  className={classes.profileBox}
                  sx={{
                    borderTop: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderBottom: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderRight: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    borderLeft: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    marginTop: "2rem",
                    marginLeft: "2rem",
                    borderRadius: "50%",
                    transform: "rotate(-30deg)",
                  }}
                >
                  <Avatar
                    alt="Akanni Trend"
                    src={trendImg}
                    sx={{
                      height: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      width: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      transform: "rotate(30deg)",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: {
                      xs: "4rem",
                      md: "auto",
                      lg: "15rem",
                      sm: "2rem",
                    },
                    marginLeft: { xs: "2rem", md: "auto", lg: "0", sm: "1rem" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "16px",
                        lg: "18px",
                        sm: "16px",
                      },
                      fontWeight: {
                        xs: "bold",
                        md: "bold",
                        lg: "bold",
                        sm: "bold",
                      },
                    }}
                  >
                    Akanni Trend
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#22AACC",
                    }}
                  >
                    Lagos, Surulere
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: {
                    xs: "1rem",
                    md: "auto",
                    lg: "2rem",
                    sm: "2rem",
                  },
                  marginRight: "2rem",
                  marginTop: { xs: "2rem", md: "auto", lg: "3rem", sm: "2rem" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  Whenever you hear @Coinstick the first thing that should come
                  to your mind is they gat the baddest rate Menhh their rates
                  are madddd!!! and they are different from any other crypto
                  plug I had traded with.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  Guys I can’t wait for the launch of your App trust me you are
                  going higher and please🙏🙏🙏 continue the good work and
                  improve more on the pace of transactions.
                </Typography>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    // marginBottom:'3rem',
                  }}
                >
                  Thanks.
                </Typography>
              </Box>
            </Paper>

            <Paper className={classes.carouselMobilePaper} elevation={3}>
              <Box
                sx={{
                  display: { xs: "flex", md: "none", lg: "flex", sm: "flex" },
                }}
              >
                <Box
                  className={classes.profileBox}
                  sx={{
                    borderTop: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderBottom: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderRight: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    borderLeft: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    marginTop: "2rem",
                    marginLeft: "2rem",
                    borderRadius: "50%",
                    transform: "rotate(-30deg)",
                  }}
                >
                  <Avatar
                    alt="Tosin Adeyemo"
                    src={tosinImg}
                    sx={{
                      height: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      width: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      transform: "rotate(30deg)",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: {
                      xs: "4rem",
                      md: "auto",
                      lg: "15rem",
                      sm: "2rem",
                    },
                    marginLeft: { xs: "2rem", md: "auto", lg: "0", sm: "1rem" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "16px",
                        lg: "18px",
                        sm: "16px",
                      },
                      fontWeight: {
                        xs: "bold",
                        md: "bold",
                        lg: "bold",
                        sm: "bold",
                      },
                    }}
                  >
                    Tosin Adeyemo
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#22AACC",
                    }}
                  >
                    Lagos, Magodo
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: {
                    xs: "1rem",
                    md: "auto",
                    lg: "2rem",
                    sm: "2rem",
                  },
                  marginRight: "2rem",
                  marginTop: { xs: "2rem", md: "auto", lg: "3rem", sm: "2rem" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  I'll rather trade with coinstick.. your service is top notch.
                  Fast transaction and very reliable! With coinstick I have
                  nothing to worry about.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  Sincerely I was scared 😦 at first because of series of
                  encounters I had in the past but decided to give them a try
                  and today am happy I made the right decision.
                </Typography>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    // marginBottom:'3rem',
                  }}
                >
                  Keep it up!
                </Typography>
              </Box>
            </Paper>

            <Paper className={classes.carouselMobilePaper} elevation={3}>
              <Box
                sx={{
                  display: { xs: "flex", md: "none", lg: "flex", sm: "flex" },
                }}
              >
                <Box
                  className={classes.profileBox}
                  sx={{
                    borderTop: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderBottom: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid white",
                      sm: "none",
                    },
                    borderRight: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    borderLeft: {
                      xs: "none",
                      md: "none",
                      lg: "4px solid #22AACC",
                      sm: "none",
                    },
                    marginTop: "2rem",
                    marginLeft: "2rem",
                    borderRadius: "50%",
                    transform: "rotate(-30deg)",
                  }}
                >
                  <Avatar
                    alt="Edi Comfort"
                    src={ebiComfortImg}
                    sx={{
                      height: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      width: {
                        xs: "10rem",
                        md: "10rem",
                        lg: "20rem",
                        sm: "10rem",
                      },
                      transform: "rotate(30deg)",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: {
                      xs: "4rem",
                      md: "auto",
                      lg: "15rem",
                      sm: "2rem",
                    },
                    marginLeft: { xs: "2rem", md: "auto", lg: "0", sm: "1rem" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "16px",
                        lg: "18px",
                        sm: "16px",
                      },
                      fontWeight: {
                        xs: "bold",
                        md: "bold",
                        lg: "bold",
                        sm: "bold",
                      },
                    }}
                  >
                    Ebi Comfort
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#22AACC",
                    }}
                  >
                    Lagos, Magodo
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: {
                    xs: "1rem",
                    md: "auto",
                    lg: "2rem",
                    sm: "2rem",
                  },
                  marginRight: "2rem",
                  marginTop: { xs: "2rem", md: "auto", lg: "3rem", sm: "2rem" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  Coinstick I’ll say is a fantastic organisation! Their
                  transactions are fast and reliable from beginning to end of
                  the process. The team are really informed and go the extra
                  mile at every stage to update customers on the best rate.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    marginBottom: {
                      xs: "2rem",
                      md: "2rem",
                      lg: "3rem",
                      sm: "2rem",
                    },
                  }}
                >
                  I would recommend them unreservedly. Keep up the good work ,
                  we hope you continue to make transactions more easy as we
                  anticipate your mobile app.
                </Typography>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "13px",
                      lg: "18px",
                      sm: "13px",
                    },
                    // marginBottom:'3rem',
                  }}
                >
                  Thanks You.
                </Typography>
              </Box>
            </Paper>
          </Carousel>
        </Box>
        {/*Responsive view     */}
        {/* <UserCarousel users={users} /> */}
        <Box
          sx={{
            display: { lg: "none" },
          }}
        >
          <UserReviewCarousel />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CustomersDelights;
