import {
  Box,
  Typography,
  //   Button,
  Paper,
  Divider,
  MenuList,
  MenuItem,
  Link,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import coinstickHlogo from "../asset/coinsticklogo.jpeg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./NavBar.css";
import "./Header.css";
// import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import navlogo from "../asset/coinsticknewlogo.png";
import logos_whatsapp from "../asset/logos_whatsapp.png";
import whatsapplogo1 from "../asset/newwhatsapplogo.png";
import playstoreImg from "../asset/newplaystorelogo.png";
import applestoreImg from "../asset/newappstorelogo.png";

import coinstickLogo from "../asset/coinstickLogo.png";
import "./HomePage.css";
import "./Main.css";

const useStyles = makeStyles((theme) => ({
  playstoreImg: {
    borderRadius: "7px",
    width: "12rem",
    height: "4rem",
    "@media (max-width: 600px)": {
      width: "10rem",
      height: "4rem",
    },
    "@media (min-width: 375px) and (max-width: 435px)": {
      width: "10rem",
      height: "4rem",
    },
    "@media (min-width: 340px) and (max-width: 374px)": {
      width: "9rem",
      height: "4rem",
    },
    "@media (min-width: 300px) and (max-width: 339px)": {
      width: "8rem",
      height: "4rem",
    },
  },
  playstoreImg1: {
    borderRadius: "7px",
    "@media (max-width: 600px)": {
      width: "15rem",
      height: "4rem",
    },
    "@media (min-width: 375px) and (max-width: 435px)": {
      width: "14rem",
      height: "4rem",
    },
    "@media (min-width: 340px) and (max-width: 374px)": {
      width: "14rem",
      height: "4rem",
    },
    "@media (min-width: 300px) and (max-width: 339px)": {
      width: "12rem",
      height: "4rem",
    },
  },
  boxPack: {
    marginLeft: "18rem",
    marginBottom: "5rem",
    marginTop: "0rem",
    backgroundColor: "white",
    "@media (min-width: 1200px)": {
      marginLeft: "18rem",
      marginBottom: "5rem",
      marginTop: "1rem",
    },
  },
  boxPackB: {
    // marginLeft:"-20rem",
    // marginBottom:"15rem",
    // marginTop:"-2rem !important",
    position: "fixed",
    height: "8rem",
    paddingBottom: "1rem",
    width: "100%",
    backgroundColor: "white",
    zIndex: "10",
    // "@media (min-width: 1200px)":{
    //     marginTop:"0rem",
    //     position: "fixed",
    //     height: "8rem",
    //     paddingBottom:"1rem",
    //     width: "189.9rem",
    //     backgroundColor: "white",
    //     zIndex:"10",
    // }
  },
  companyt: {
    fontSize: "18px !important",
    fontWeight: "bold !important",

    "@media (min-width: 1200px)": {
      fontSize: "18px !important",
      fontWeight: "bold !important",
    },
  },
  "": {
    fontSize: "18px !important",
    fontWeight: "bold !important",
    "@media (min-width: 1200px)": {
      fontSize: "18px !important",
      fontWeight: "bold !important",
    },
  },
  customerT: {
    fontSize: "18px !important",
    fontWeight: "bold !important",
    "@media (min-width: 1200px)": {
      fontSize: "18px !important",
      fontWeight: "bold !important",
    },
  },
  careerT: {
    fontSize: "18px !important",
    fontWeight: "bold !important",
    "@media (min-width: 1200px)": {
      fontSize: "18px !important",
      fontWeight: "bold !important",
    },
  },
  headerBox: {
    display: "flex",
    gap: "5%",
    marginLeft: "10%",
    width: "100% !important",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      display: "flex",
      gap: "10%",
      marginLeft: "10%",
    },
    "@media (min-width: 1025px) and (max-width: 1200px)": {
      display: "flex",
      gap: "5%",
      marginLeft: "10%",
    },
    "@media (min-width: 1201px)": {
      display: "flex",
      gap: "5%",
      marginLeft: "10%",
    },
    // 1025px — 1200px
    // 769px — 1024p
  },
  companyFlex: {
    display: "flex",
    marginTop: "2rem",
    marginLeft: "5%",
    // gap: "5%",
    // justifyContent: "space-evenly",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      display: "flex",
      marginTop: "2rem",
      marginLeft: "5%",
      gap: "5%",
    },
    "@media (min-width: 1025px) and (max-width: 1200px)": {
      display: "flex",
      marginTop: "2rem",
      marginLeft: "5%",
      gap: "5%",
    },
    "@media (min-width: 1201px)": {
      display: "flex",
      marginTop: "2rem",
      marginLeft: "5%",
      gap: "5%",
    },
  },
  btnBox: {
    marginTop: "0.8rem",
    marginLeft: "12rem",
    display: "flex",
    gap: "3rem",
    "@media (min-width: 1200px)": {
      marginTop: "0.8rem",
      marginLeft: "12rem",
      display: "flex",
      gap: "3rem",
    },
  },
  signupBtn: {
    fontSize: "16px !important",
    backgroundColor: "white !important",
    border: "1px solid #22AACC !important",
    borderRadius: "1rem !important",
    paddingLeft: "4rem !important",
    paddingRight: "4rem !important",
    textDecorationLine: "none !important",
    "@media (min-width: 1200px)": {
      fontSize: "16px !important",
      backgroundColor: "white !important",
      border: "1px solid #22AACC !important",
      borderRadius: "1rem !important",
      paddingLeft: "4rem !important",
      paddingRight: "4rem !important",
      textDecorationLine: "none !important",
    },
  },
  registerBtn: {
    fontSize: "16px !important",
    backgroundColor: "#22AACC !important",
    border: "1px solid #22AACC !important",
    borderRadius: "1rem !important",
    paddingLeft: "4rem !important",
    paddingRight: "4rem !important",
    color: "white !important",
    textDecorationLine: "none !important",
    "@media (min-width: 1200px)": {
      fontSize: "16px !important",
      backgroundColor: "#22AACC !important",
      border: "1px solid #22AACC !important",
      borderRadius: "1rem !important",
      paddingLeft: "4rem !important",
      paddingRight: "4rem !important",
      color: "white !important",
      textDecorationLine: "none !important",
    },
  },
  allBox: {
    marginLeft: "-20rem",
    "@media (min-width: 1200px)": {
      marginLeft: "-20rem",
    },
  },
  btnlink: {
    textDecorationLine: "none",
  },
  companyBox: {
    display: "none",
    "&:hover": {
      display: "block",
    },
    // color:"black",
    // fontSize:"16px",
    // backgroundColor:"whitesmoke",
    // paddingTop:"1rem",
    // paddingBottom:"1rem",
    // paddingRight:"2rem",
    // paddingLeft:"1rem",
    // textDecorationColor:"black",
    // textDecorationLine: "none",
    // cursor: "pointer",
    // height:"10rem",
  },
  companyBox2: {
    display: "none",
    color: "black",
    fontSize: "16px",
    backgroundColor: "whitesmoke",
    paddingTop: "1rem",
    paddingBottom: "3rem",
    paddingRight: "2rem",
    paddingLeft: "1rem",
    textDecorationColor: "black",
    textDecorationLine: "none",
    cursor: "pointer",
  },
  dropdownLine: {
    textDecorationLine: "none",
    color: "black",
  },
}));

const Header = () => {
  const classes = useStyles();

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  var els = document.getElementsByTagName("Link");

  for (var el = 0; el < els.length; el++) {
    els[el].style["text-decoration"] = "none";
  }

  return (
    <React.Fragment>
      <div className="headerdiv">
        <div className="innerHeaderDiv">
          <div>
            <img className={"coinstickLogo"} src={coinstickLogo} alt="" />
          </div>
          <div className="homediv">
            <h4>Home</h4>
            <h4>Cashback Points</h4>
            <h4>Referrals</h4>
          </div>
        </div>
      </div>

      {/* SIDEBAR START */}
      <div className={"nav-rearrangement"}>
        <div className={"nav-rearrange"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // marginRight: "5.5rem",
            }}
          >
            <a href={"/"}>
              <img src={navlogo} className="nav-logo" alt={""} />
            </a>
            {/* <a
                href="https://wa.link/hdkdn4"
                target="_blank"
                without
                rel="noopener noreferrer"
                className={"edit-link-pro"}
              >
                <Paper
                  sx={{
                    width: {
                      xs: "11rem",
                      md: "11rem",
                      lg: "45rem",
                      sm: "11rem",
                    },
                    // padding:"2rem",
                    paddingTop: {
                      xs: "0.5rem",
                      md: "0.5rem",
                      lg: "0.5rem",
                      sm: "0.5rem",
                    },
                    paddingBottom: {
                      xs: "0.5rem",
                      md: "0.5rem",
                      lg: "0.5rem",
                      sm: "0.5rem",
                    },
                    paddingLeft: {
                      xs: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      sm: "1rem",
                    },
                    paddingRight: {
                      xs: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      sm: "1rem",
                    },
                    color: "black",
                    backgroundColor: "#22AACC",
                    // height:"3.5rem",
                    display: "flex",
                    borderRadius: {
                      xs: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      sm: "1rem",
                    },
                    marginTop: {
                      xs: "1rem",
                      md: "1rem",
                      lg: "1rem",
                      sm: "1rem",
                    },
                    // marginRight:"-5rem"
                    // textAlign:"center",
                  }}
                >
                  <img
                    style={{ height: "2rem", width: "2rem" }}
                    src={logos_whatsapp}
                    alt=""
                  />
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "8px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    variant=""
                  >
                    Trade faster on Whatsapp
                  </Typography>
                </Paper>
              </a> */}
          </Box>
          {/*<span className={"navbar-coinstick"}>Coinstick</span>*/}
          <IconContext.Provider value={{ color: "#22AACC" }}>
            <div className={"navbar1"} style={{ marginTop: "-5rem" }}>
              <Link to={""} className={"menu-bars"}>
                <FaIcons.FaBars
                  onClick={showSidebar}
                  className={"fa-big"}
                  style={{ color: "#22AACC" }}
                />
              </Link>
            </div>
            <nav
              className={sidebar ? "nav-menu active" : "nav-menu"}
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className={"nav-menu-items"}>
                <li
                  className={"navbar-toggle"}
                  style={{ backgroundColor: "#22AACC" }}
                >
                  <Link to={"#"} className={"menu-bars"}>
                    <AiIcons.AiOutlineClose
                      onClick={showSidebar}
                      style={{
                        color: "black",
                        padding: "30px",
                        paddingLeft: "20px",
                      }}
                    />
                    {/*<IoIcons.IoIosClose className={""}/>*/}
                  </Link>
                </li>
                <div className={"nav-div"}>
                  <div className="storediv">
                    <Link
                      href={"https://wa.link/ks4v9u"}
                      target="_blank"
                      without
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ borderRadius: "10px", marginLeft: "-6px" }}
                        src={whatsapplogo1}
                        className="whatsapplogolink"
                      />
                    </Link>
                    <Link
                      href={
                        "https://jopmw.test-app.link/Playstore"
                      }
                      target="_blank"
                      without
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ borderRadius: "10px" }}
                        src={playstoreImg}
                        alt=""
                        className="whatsapplogolink"
                      />
                    </Link>

                    <Link
                      href={
                        "https://jopmw.test-app.link/AppStore"
                      }
                      target="_blank"
                      without
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ borderRadius: "10px" }}
                        src={applestoreImg}
                        alt=""
                        className="whatsapplogolink"
                      />
                    </Link>
                    {/* <Link
                      href={
                        "https://play.google.com/store/apps/details?id=com.coinstick.myapp"
                      }
                      target="_blank"
                      without
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ borderRadius: "10px" }}
                        src={playstoreImg}
                        alt=""
                        className="storeimage1"
                      />
                    </Link>

                    <Link
                      href={
                        "https://play.google.com/store/apps/details?id=com.coinstick.myapp"
                      }
                      target="_blank"
                      without
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ borderRadius: "10px" }}
                        src={applestoreImg}
                        alt=""
                        className="storeimage1"
                      />
                    </Link> */}
                  </div>
                </div>
              </div>
            </nav>
          </IconContext.Provider>
        </div>
      </div>
      {/* SIDEBAR END */}
    </React.Fragment>
  );
};

export default Header;
