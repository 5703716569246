// import { Box, Typography } from "@mui/material"
import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import facebookLogo from "../asset/facebookLogo.jpeg";
import instagramLogo from "../asset/igLogo.jpeg";
import linkedinLogo from "../asset/linkedinLogo.jpeg";
import twitterLogo from "../asset/twitterLogo.jpeg";
import tiktokLogo from "../asset/tiktokLogo.jpeg";
import youtubeLogo from "../asset/youtubeLogo.jpeg";
import whatsappLogo from "../asset/whatsappLogo.jpeg";
import "./SectionO.css";

const useStyles = makeStyles((theme) => ({
  californiat: {
    fontSize: "16px !important",
  },
  lagost: {
    fontSize: "16px !important",
  },
  contactt: {
    fontSize: "18px !important",
    color: "#22AACC",
    marginBottom: "3px !important",
    marginLeft: "20rem !important",
  },
  hellot: {
    fontSize: "18px !important",
    color: "#22AACC",
  },
  allBox: {
    display: "flex",
    gap: "15rem",
    marginTop: "1rem",
    marginLeft: "20rem",
  },
  helloBox: {
    // marginRight:"42rem !important",
  },
  lagosheader: {
    marginBottom: "2rem !important",
  },
  californiaheader: {
    marginBottom: "2rem !important",
  },
  socialLogo: {
    display: "flex",
    gap: "3rem",
    marginTop: "4rem",
  },
  facebookLogo: {
    // width:"6rem",
    // height:"6rem",
    borderRadius: "50%",
  },
}));

const FooterSecThree = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography
        sx={{
          marginLeft: { xs: "2%", md: "10rem", lg: "10%", sm: "2% " },
          marginBottom: { xs: "0rem", md: "10rem", lg: "2rem", sm: "2rem" },
          fontSize: { xs: "18px", md: "18px", lg: "18px", sm: "18px" },
          color: { xs: "#22AACC", md: "#22AACC", lg: "#22AACC", sm: "#22AACC" },
        }}
      >
        Contact
      </Typography>

      <Box
        className={classes.allBox}
        sx={{
          marginLeft: { xs: "2%", md: "10rem", lg: "10%", sm: "2% " },
          display: { xs: "flex", md: "flex", lg: "flex", sm: "flex" },
          flexDirection: {
            xs: "column",
            md: "column",
            lg: "row",
            sm: "column",
          },
          marginBottom: "20px",
          gap: { xs: "4rem", md: "2rem", lg: "14rem", sm: "2rem" },
        }}
      >
        <Box className={classes.helloBox}>
          <Box>
            <Typography className={classes.hellot}>
              <u>hello@coinstick.co</u>
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "flex", lg: "flex", sm: "flex" },
              gap: { xs: "10px", md: "1rem", lg: "1rem", sm: "10px" },
              marginTop: { xs: "1rem", md: "1rem", lg: "4rem", sm: "1rem" },
              // display:"flex",
              // gap: "3rem",
              // marginTop:"4rem"
            }}
          >
            <Link
              href={"https://web.facebook.com/CoinstickHQ"}
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              <img src={facebookLogo} alt={""} className={"facebookLogo"} />
            </Link>

            <Link
              href={"https://www.instagram.com/coinstick.hq"}
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              <img src={instagramLogo} alt={""} className={"facebookLogo"} />
            </Link>

            <Link href={""} target="_blank" without rel="noopener noreferrer">
              <img src={linkedinLogo} alt={""} className={"facebookLogo"} />
            </Link>

            <Link
              href={"https://twitter.com/coinstickHQ"}
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              <img src={twitterLogo} alt={""} className={"facebookLogo"} />
            </Link>

            <Link href={""} target="_blank" without rel="noopener noreferrer">
              <img src={tiktokLogo} alt={""} className={"facebookLogo"} />
            </Link>

            <Link href={""} target="_blank" without rel="noopener noreferrer">
              <img src={youtubeLogo} alt={""} className={"facebookLogo"} />
            </Link>

            <Link
              href={"https://wa.link/0kormb"}
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              {" "}
              <img
                src={whatsappLogo}
                alt={""}
                className={"facebookLogo"}
              />{" "}
            </Link>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "flex", md: "flex", lg: "flex", sm: "flex" },
            gap: { xs: "4rem", md: "1rem", lg: "4rem", sm: "20px" },
            flexDirection: { xs: "row", md: "column", lg: "row", sm: "column" },
            marginTop: { xs: "1rem", md: "1rem", lg: "4rem", sm: "1rem" },
          }}
        >
          <Box>
            <Typography
              variant="h4"
              sx={{
                marginBottom: {
                  xs: "1rem",
                  md: "2rem",
                  lg: "2rem",
                  sm: "2rem",
                },
              }}
            >
              Lagos
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "13px", md: "13px", lg: "16px", sm: "13px" },
              }}
            >
              38, Montgomery Road,
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "13px", lg: "16px", sm: "13px" },
              }}
            >
              Yaba, Lagos,
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "13px", lg: "16px", sm: "13px" },
              }}
            >
              Nigeria.
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="h4"
              sx={{
                marginBottom: {
                  xs: "1rem",
                  md: "2rem",
                  lg: "2rem",
                  sm: "2rem",
                },
              }}
            >
              California
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "13px", md: "13px", lg: "16px", sm: "13px" },
              }}
            >
              2837 Plumeigh Ave,
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "13px", lg: "16px", sm: "13px" },
              }}
            >
              Antioch, CA 94509-4833,
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "13px", lg: "16px", sm: "13px" },
              }}
            >
              United State.
            </Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default FooterSecThree;
