import { Box, Button, Typography, Link } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import imageBg from "../asset/snakebg.jpeg";
// import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  playstoreImg: {
    borderRadius: "7px",
  },
  webImg: {
    borderRadius: "7px",
  },
  applestoreImg: {
    borderRadius: "7px",
  },
  flexBoxB: {
    // display: "flex",
    // gap: "44rem",
    // marginTop: "2rem",
    // marginBottom: "8rem",
  },
  allBox: {
    marginTop: "10rem",
    height: "40rem",
    "@media (max-width: 600px)": {
      marginTop: "3rem",
    },
    // marginLeft: "20rem",
    // backgroundImage:"url( ${coinstick_project/src/image/snakebg.jpeg} ) !important",
  },
  allContentBox: {
    backgroundImage: `url(${imageBg})`,

    // marginLeft:"-20rem",
    backgroundRepeat: "no-repeat",
    marginTop: "10rem",
    marginBottom: "5rem",
    // height: "45rem",
    // width: "189.7rem",
    width: "100%",
    backgroundSize: "cover",
    "@media (max-width: 600px)": {
      marginTop: "1rem",
    },
    // paddingRight:"20rem",
    // marginRight:"-20rem"
  },
  buyBoxt: {
    fontSize: "6rem !important",
    fontWeight: "bolder !important",
    // marginTop: "3rem !important",
  },
  coinstickNow: {
    fontSize: "18px !important",
    // marginTop: "-3rem !important",
  },
  buyBox: {
    width: "40% !important",
  },
  getBtn: {
    // width:{xs: '29rem', md: '10%', lg: '30rem', sm:'30rem'},
    // height:{xs: '6rem', md:'6rem', lg: '6rem', sm:'6rem'},
    // color:{xs: 'white', md: 'white', lg: 'white', sm:'white'},
    // fontSize:{xs: '18px', md: '18px', lg: '18px', sm:'18px'},
    // backgroundColor:{xs:'#2AACB0', md: '#2AACB0', lg: '#2AACB0', sm: '#2AACB0'}

    width: "280px !important",
    height: "70px !important",
    color: "white !important",
    fontSize: "18px !important",
    backgroundColor: "#22AACC !important",
    borderRadius: "1rem !important",
    "@media (max-width: 600px)": {
      width: "90% !important",
      height: "4rem !important",
      color: "white !important",
      fontSize: "18px !important",
      backgroundColor: "#22AACC !important",
      borderRadius: "10px !important",
    },
    "@media (min-width: 320px) and (max-width: 350px)": {
      width: "90% !important",
      height: "4rem !important",
      color: "white !important",
      fontSize: "16px !important",
      backgroundColor: "#22AACC !important",
      borderRadius: "10px !important",
    },
    "@media (min-width: 290px) and (max-width: 319px)": {
      width: "90% !important",
      height: "4rem !important",
      color: "white !important",
      fontSize: "14px !important",
      backgroundColor: "#22AACC !important",
      borderRadius: "10px !important",
    },
  },
  getStartedBox: {
    marginTop: "10rem !important",
  },
}));

const FooterTop = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.allContentBox}>
        <Box
          className={classes.allBox}
          sx={{
            marginLeft: { xs: "2rem", md: "10rem", lg: "10%", sm: "2%" },
            marginRight: { xs: "2rem", md: "10rem", lg: "10%", sm: "2%" },
          }}
        >
          <Box>
            <Typography
              sx={{
                textAlign: {
                  xs: "center",
                  md: "center",
                  lg: "left",
                  sm: "center",
                },
                fontSize: { xs: "18px", md: "18px", lg: "18px", sm: "18px" },
              }}
            >
              Try Coinstick Now
            </Typography>
          </Box>
          <Box
            className={classes.flexBoxB}
            sx={{
              display: { xs: "flex", md: "flex", lg: "flex", sm: "flex" },
              //   gap: { xs: "1rem", md: "1rem", lg: "44rem", sm: "3rem" },
              marginTop: { xs: "2rem", md: "2rem", lg: "2rem", sm: "1rem" },
              marginBottom: { xs: "2rem", md: "2rem", lg: "8rem", sm: "2rem" },
              justifyContent: "space-between",
              alignItems: { lg: "center" },
              flexDirection: {
                xs: "column",
                md: "column",
                lg: "row",
                sm: "column",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "100%", lg: "40%", sm: "100%" },
              }}
            >
              <Typography
                sx={{
                  textAlign: {
                    xs: "center",
                    md: "center",
                    lg: "left",
                    sm: "center",
                  },
                  fontSize: { xs: "28px", md: "18px", lg: "46px", sm: "24px" },
                  fontWeight: {
                    xs: "bold",
                    md: "bold",
                    lg: "bold",
                    sm: "bold",
                  },
                }}
              >
                Sell your giftcards to Naira in 5 minutes
              </Typography>
            </Box>
            <Box
              className={classes.getStartedBox}
              sx={{
                marginTop: { xs: "2rem", md: "2rem", lg: "-4rem", sm: "1rem" },
                marginLeft: { xs: "2%", md: "10%", lg: "0", sm: "2%" },
              }}
            >
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.coinstick.myapp"
                }
                target="_blank"
                without
                rel="noopener noreferrer"
                style={{ textDecorationLine: "none", color: "black" }}
              >
                <Button className={classes.getBtn}>Get started for free</Button>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default FooterTop;
