import React from "react";
import "./SectionOne.css";
import playstore from "../asset/google_play_w.svg";
import appstore from "../asset/app_store_w.svg";
import whatsapp from "../asset/whatsapp_w.svg";
import cashtonaira from "../asset/cashtonaira.png";
import hereisthedeal from "../asset/hereisthedeal.png";
import giftcardtonaira from "../asset/giftcardtonaira.png";
import tradegiftcard from "../asset/tradegiftcard.png";
import tradegiftcardheader from "../asset/cashback2.png";
import downloadillustration from "../asset/2dillustrationcopy.png";

const SectionOne = () => {
  return (
    <div className="sectiondiv">
      <div className="header">
        <h2>Coinstick - Trade gift cards for cash</h2>
        <img className="sell_giftcardheaderimg" src={cashtonaira} alt="" />
        <p>Join over 40k users to trade bigger and safer with Coinstick</p>
      </div>

      <div className="allcard">
        <div className="card">
          <div className="imagestyle">
            <img src="https://thequchange.com/IMG/market_leading.svg" alt="" />
          </div>
          <div className="contentdiv">
            <div
              // style={{ marginTop: "5px", paddingLeft: "5px" }}
              className="innerContentDiv"
            >
              <p className="contentheader">Best rate you can ever get</p>
              <p className="contenttext">
                Our rates are mouth-watering 😋 and juicy as we have the best
                rate for all gift card transactions in the market.
              </p>
            </div>
          </div>
        </div>

        {/* second card */}
        <div className="card">
          <div className="imagestyle">
            <img src="https://thequchange.com/IMG/market_leading.svg" alt="" />
          </div>
          <div className="contentdiv">
            <div
              // style={{ marginTop: "5px", paddingLeft: "5px" }}
              className="innerContentDiv"
            >
              <p className="contentheader">Seamless transactions</p>
              <p className="contenttext">
                We have designed our platform in a way that will make your
                transactions seamless and effortless. It is very easy to use 👌.
              </p>
            </div>
          </div>
        </div>

        {/* third card */}
        <div className="card">
          <div className="imagestyle">
            <img src="https://thequchange.com/IMG/market_leading.svg" alt="" />
          </div>
          <div className="contentdiv">
            <div
              // style={{ marginTop: "5px", paddingLeft: "5px" }}
              className="innerContentDiv"
            >
              <p className="contentheader">24/7 Customer service</p>
              <p className="contenttext">
                We have all hands on deck to make you enjoy your transaction
                with us. Click here to reach out to us.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="buttonstyle">
        <a href={"https://jopmw.test-app.link/Playstore"}
                target="_blank"
                without
                rel="noopener noreferrer">

        <img   src={playstore} alt="" />
        </a>
        <a href={"https://jopmw.test-app.link/AppStore"}
              target="_blank"
              without
              rel="noopener noreferrer">
        <img  src={appstore} alt="" />
              </a>
              <a href={"https://wa.link/hdkdn4"}
              target="_blank"
              without
              rel="noopener noreferrer">
        <img  src={whatsapp} alt="" />
              </a>
      </div>

      <div className="hereisthedeal">
        <img
          className="thedealimg"
          src="https://thequchange.com/IMG/heres_the_deal.svg"
          alt=""
        />

        <div className="thedealtextdiv">
          <img src={hereisthedeal} alt="" className="hereisthedealimg" />
          <p>
            Research shows that about 6% to 10% of gift cards end up being
            unused each year. Why keep your gift card somewhere you might later
            forget when you can trade it for instant cash with Coinstick???
          </p>
          <p>
            Don't let those cards go to waste. Download the Coinstick app now to
            convert them to cash instantly ✌️
          </p>
        </div>
      </div>

      <div className="giftcarddiv">
        <div className="giftcardtextdiv">
          <img className="giftcarddivimg" src={giftcardtonaira} alt="" />
          <div className="giftcardtext">
            <p>
              Download the Coinstick app on App Store or Google Play and sign up
              for an account. It’s really easy to use, much like a site to
              redeem a gift card to naira except that it has all the convenience
              and intuitiveness of a reliable app on your phone.
            </p>
            <p>
              On the Coinstick app, you can trade all types of gift cards for
              instant payment such as Amazon, Steam, Apple iTunes, Sephora,
              Visa, eBay, Google Play, and more. All you have to do is click on
              the sell button and select the card/cards you want to trade from
              the options you have on the app.
            </p>
          </div>
        </div>
        <div className="sellgiftcardimgdiv">
          <img className="giftcardimg" src={downloadillustration} alt="" />
        </div>
      </div>

      <div className="sectionlightorange">
        <div className="sectionlightorangediv">
          <div className="sellgiftcardimgdiv">
            <img
              className="sectionlightorangeimage"
              // className="quicklyimg"
              src={tradegiftcardheader}
              alt=""
            />
          </div>
          <div className="thedealtextdiv">
            <img src={tradegiftcard} alt="" className="quicklyimg" />
            <p>
              When you here instant transactions, it can only be Coinstick.
              Though some cards may take a bit longer to confirm, you get paid
              immediately it is confirmed, No glitch 💯
            </p>
            <p>
              Don’t let those cards go to waste. Convert gift cards for naira
              and get some extra money in your pocket! It’s quick, secure, and
              easy to convert any gift card to naira using Coinstick. Download
              it today!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
