import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ebiComfortImg from "../asset/ebiComfortImg.jpeg";
import zahkiImg from "../asset/zahkiImg.jpeg";
import ridwanImg from "../asset/ridwanImg.jpeg";
import trendImg from "../asset/trendImg.jpeg";
import tosinImg from "../asset/tosinImg.jpeg";
import { Box, Paper, Typography, Avatar } from "@mui/material";
import "./UserReviewCarousel.css";

const UserReviewCarousel = () => {
  const userReviews = [
    {
      userImage: zahkiImg,
      review:
        "Am happy with the services offer by @coinstickHQ. I actually needed a genuine plug to exchange my gift cards. Luckily, I came across them on instagram during their sponsored post. Sincerely, I was scared 🥲 at first, you know previous bad experiences but decided to give them a try, and no regrets so far. 👍👍 @coinstick continue the good work.",
      userName: "Zahki Lion",
      location: "Yaba, Lagos.",
    },
    {
      userImage: ridwanImg,
      review:
        "“Phenomenal; that’s just the word for Coinstick, best at what they do with maximum dedication to satisfying the partners and customers needs” New development should set in like your mobile app. Don't relent on the good work you have started.",
      userName: "Ganiu Ridwan",
      location: "Ikorodu, Lagos.",
    },
    {
      userImage: trendImg,
      review:
        "Whenever you hear @Coinstick the first thing that should come to your mind is they gat the baddest rate Mehhn their rates are madddd!!! and they are different from any other exchange plug I have traded with. Guys I can’t wait for the launch of your App trust me you are going higher and please🙏🙏🙏 continue the good work and improve more on the pace of transactions.",
      userName: "Trend Wears",
      location: "Surulere, Lagos.",
    },
    {
      userImage: tosinImg,
      review:
        "I'll rather trade with coinstick.. your service is top notch. Fast transaction and very reliable! With coinstick I have nothing to worry about. Though I was scared 😦 at first due to the of series of encounters I had in the past but decided to give them a try and today am happy I made the right decision. Keep it up! ",
      userName: "Oluwatosin Adeyemo",
      location: "Magodo, Lagos.",
    },
    {
      userImage: ebiComfortImg,
      review:
        "Coinstick is a reliable, fast, and consistent Platform for redeeming your gift cards, they give good rates ever since I have been transacting with them. I appreciate so much, I will give a 4stars for now. New development should set in like your mobileapp. Keep the good work up.",
      userName: "Ebi Comfort",
      location: "Surulere, Lagos.",
    },
  ];

  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000} // Adjust as needed
      stopOnHover={true}
      swipeable={true}
    >
      {userReviews.map((review, index) => (
        <div key={index} className="review-item">
          {/* <img src={review.userImage} alt={`User ${index}`} /> */}
          <Box
            sx={{
              borderTop: {
                xs: "none",
                md: "none",
                lg: "4px solid white",
                sm: "none",
              },
              borderBottom: {
                xs: "none",
                md: "none",
                lg: "4px solid white",
                sm: "none",
              },
              borderRight: {
                xs: "none",
                md: "none",
                lg: "4px solid #22AACC",
                sm: "none",
              },
              borderLeft: {
                xs: "none",
                md: "none",
                lg: "4px solid #22AACC",
                sm: "none",
              },
              marginTop: "0rem",
              marginLeft: "2%",
              borderRadius: "50%",
              transform: "rotate(-30deg)",
            }}
          >
            <Avatar
              alt="Zahki Lion"
              src={review.userImage}
              sx={{
                height: {
                  xs: "10rem",
                  md: "10rem",
                  lg: "20rem",
                  sm: "10rem",
                },
                width: {
                  xs: "10rem",
                  md: "10rem",
                  lg: "20rem",
                  sm: "10rem",
                },
                transform: "rotate(30deg)",
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: {
                xs: "1rem",
                md: "auto",
                lg: "15rem",
                sm: "1rem",
              },
              marginBottom: {
                xs: "2rem",
                md: "auto",
                lg: "0",
                sm: "2rem",
              },
              marginLeft: { xs: "2rem", md: "auto", lg: "0", sm: "1rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "16px",
                  md: "16px",
                  lg: "18px",
                  sm: "16px",
                },
                fontWeight: {
                  xs: "bold",
                  md: "bold",
                  lg: "bold",
                  sm: "bold",
                },
              }}
            >
              {review?.userName}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "#22AACC",
              }}
            >
              {review?.location}
            </Typography>
          </Box>
          <p className="review-text">{review.review}</p>
        </div>
      ))}
    </Carousel>
  );
};

export default UserReviewCarousel;
