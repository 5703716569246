import React from "react";
import CustomersDelights from "./CustomersDelights";
import Footer from "./Footer";
import FooterSecThree from "./FooterSecThree";
import FooterSecTwo from "./FooterSecTwo";
import FooterTop from "./FooterTop";
import Header from "./Header";
import Main from "./Main";
import SectionOne from "./SectionOne";

const HomePage = () => {
  return (
    <>
      <Header />
      <Main />
      <SectionOne />
      <CustomersDelights />
      <FooterTop />
      {/* <FooterSecOne /> */}
      <FooterSecTwo />
      <FooterSecThree />
      {/* <Footer /> */}
    </>
  );
};

export default HomePage;
