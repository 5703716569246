import React from "react";
import "./Main.css";
import playstore from "../asset/google_play.svg";
import appstore from "../asset/app_store.svg";
import whatsapplogo from "../asset/whatsapplogo.png";
import whatsapplogo1 from "../asset/newwhatsapplogo.png";
import giftcard from "../asset/giftcard.png";
import playstoreImg from "../asset/newplaystorelogo.png";
import applestoreImg from "../asset/newappstorelogo.png";
import sellyourgiftcard from "../asset/sellyourgiftcard.png";
import { Link } from "@mui/material";

const Main = () => {
  return (
    <div className="maindiv">
      <div className="alldiv">
        <div className="sellgiftcarddiv">
          <img src={sellyourgiftcard} alt="" className="sellyourgiftcard" />
          {/* <span className="sellgiftcard">Sell Your Gift Cards</span>
          <br />
          <span className="sellgiftcard">For Instant Cash</span> */}
          <p className="sellgiftcardText">
            Experience a swift, seamless, and reliable transaction by trading
            your gift cards with Coinstick
          </p>

          <div className="alllinkDiv">
            {/* <div className="linkDiv"></div> */}

            <a
              href={"https://jopmw.test-app.link/Playstore"}
              target="_blank"
              without
              rel="noopener noreferrer"
              // className={"edit-link-pro"}
            >
              <img src={playstoreImg} alt="" />
            </a>
            <a
              href={"https://jopmw.test-app.link/AppStore"}
              target="_blank"
              without
              rel="noopener noreferrer"
              // className={"edit-link-pro"}
            >
              <img src={applestoreImg} alt="" />
            </a>
            <a
              href={"https://wa.link/hdkdn4"}
              target="_blank"
              without
              rel="noopener noreferrer"
              // className={"edit-link-pro"}
            >
              <img src={whatsapplogo1} alt="" />
            </a>
          </div>

          <div className="alllinkDiv1">
            {/* <div className="linkDiv"></div> */}
            <div className="linkDivinner">
              <Link
                href={"https://jopmw.test-app.link/Playstore"}
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                <img
                  style={{ borderRadius: "10px" }}
                  src={playstoreImg}
                  alt=""
                  className="storeimage"
                />
              </Link>

              <Link
                href={"https://jopmw.test-app.link/AppStore"}
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                <img
                  style={{ borderRadius: "10px" }}
                  src={applestoreImg}
                  alt=""
                  className="storeimage"
                />
              </Link>
            </div>
            <Link
              href={"https://wa.link/ks4v9u"}
              target="_blank"
              without
              rel="noopener noreferrer"
              style={{
                textDecorationLine: "none",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              <div className="whatsapplogodiv">
                <img className="whatsapplogolink" src={whatsapplogo1} alt="" />
              </div>
            </Link>
          </div>
        </div>

        <div className="sellgiftcardimgdiv">
          <img className="sellgiftcardimg" src={giftcard} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Main;
